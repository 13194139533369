<template>
    <div class="cut-target-setting padding-top-layout">
       <div class="cut-target-setting-title">
            <page-title :title="groupId ? titleDetailUserGroup : titleRegisterUserGroup"/>
       </div>
        <div class="register-user-group">
            <div v-if="groupId">
                <button class="btn-edit edit-status" @click="handleEdit">
                    {{ $t('register_user_group.button_edit')}}
                </button>
                <button class="btn-edit delete-status" @click="isOpenDialogDelete = true">
                    {{ $t('register_user_group.button_delete')}}
                </button>
            </div>
            <div class="register-user-group-form">
                <div class="title-register-user" :class="{'just-viewing': groupId && !openEdit}">
                    <div class="title-btn">
                        <span>{{ $t('register_user_group.label_user_group_name')}}</span>
                        <div class="title-require">{{ $t('register_user_group.label_required')}}</div>
                    </div>
                    <div class="list-menu-btn">
                        <div class="list-menu-btn-text">
                            <input class="input" :class="{'error-input': errorMessage.name}" type='text' v-model="dataSubmit.name" @focus="errorMessage.name = null" />
                        </div>
                    </div>
                    <span class="error-message">{{ errorMessage.name }}</span>
                </div>
                <div class="title-register-user" :class="{'just-viewing': groupId && !openEdit}">
                    <div class="title-btn">
                        <span>{{ $t('register_user_group.label_add_user_id')}}</span>
                        <div class="helper-icon">
                            <img
                                :src="getSettingIcon('helper-icon.svg')"
                                @mouseover="changeTooltipImage($event,'','showTooltipSelectId')"
                                @mouseleave="changeTooltipImage($event, 'mouseleave','showTooltipSelectId')"
                                alt=""
                            />
                            <div
                                v-if="tooltipState.showTooltipSelectId"
                                class="helper-icon tooltip"
                                v-html="$t('register_user_group.tooltip_add_user_id')"
                            ></div>
                        </div>
                        <div class="title-require">{{ $t('register_user_group.label_required')}}</div>
                    </div>
                    <div class="list-menu-btn ">
                        <div class="list-menu-btn-text filter-organization " v-if="itemsUsers.length > 0">
                            <export-pulldown  
                                :dataList="itemsUsers" 
                                :selectedData.sync="itemsUsersNames" 
                                :itemCount="itemsUsers.length - 1" 
                                :placeholder="'組織区分'" 
                                :arrowDownBtn="true" 
                                :key="reRenderKey"
                                :isFullWidth="true"
                                :hasSearchPullDownNormal="true"
                            />
                        </div>
                    </div>
                    <div class="register-user-id">
                        <button class="btn-user-group" :disabled="isDisabledAddUserId"
                            @click="handleAddListUserId">
                            {{ $t('register_user_group.button_add')}}
                        </button>
                    </div>
                </div>
                <div class="title-register-user" :class="{'just-viewing': groupId && !openEdit}">
                    <div class="title-btn">
                        <span>{{ $t('register_user_group.label_user_id')}}</span>
                        <div class="helper-icon">
                            <img
                                :src="getSettingIcon('helper-icon.svg')"
                                @mouseover="changeTooltipImage($event,'','showTooltipIdSelected')"
                                @mouseleave="changeTooltipImage($event, 'mouseleave','showTooltipIdSelected')"
                                alt=""
                            />
                            <div
                                v-if="tooltipState.showTooltipIdSelected"
                                class="helper-icon tooltip"
                                v-html="$t('register_user_group.tooltip_user_id')"
                            ></div>
                        </div>
                    </div>
                    <div>

                        <div class="list-user-id">
                            <div class="list-user-id-selector">
                                <div class="list-user-id-selector-overflow" v-for="(user, index) in itemsUsers"  :key="index" tabindex="0">
                                    <div class="list-user-id-selector-overflow-item" v-if="dataSubmit.user_ids.includes(user.id)">
                                        <span class="list-user-id-selector-item" :class="[{ 'active-user': user.id === activeUserId }]" @click="setActiveUserId(user.id)">
                                            <span>{{ user.text }}</span>
                                            <span class="list-user-id-selector-item-remove" style="user-select: none;"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-register-user">
                    <div class="title-btn">
                        <span>{{ $t('register_user_group.label_management_of_view_authority')}}</span>
                    </div>
                    <branch-register :class="(groupId && !openEdit) && 'read-only'" v-if="groupId ? userGroupDetail : true"  :dataUserGroupDetail="userGroupDetail" @updateBranch="handleUpdateBranch" :isReadOnly="groupId && !openEdit"/>
                </div>
                <div class="title-register-user" v-if="isProducts">
                    <div class="title-btn">
                        <span>{{ $t('register_user_group.label_management_of_view_authority')}}</span>
                    </div>
                    <div
                        class="main-table custom-table user-management product-table authority-management-table"
                        :class="(groupId && !openEdit) && 'read-only'"
                    >
                        <data-table
                            :data-source="listData"
                            :grid-columns="gridColumns"
                            :init-grid="initializeGrid"
                            :showFocus="false"
                            :allowAddNew="false"
                            :isShowToolbar="false"
                            :isReadOnly="groupId && !openEdit"
                        />
                    </div>
                </div>
                
                <notification-popup :dialog="isOpenPopUpRegister" :message="messageRegisted" @submit="handleCloseDialog" />
                <question-popup :dialog="isOpenDialogDelete" :message="deleteMessage" @submit="handleSubmitDelete" :closeText="closeText" @close="isOpenDialogDelete=false" />
                <question-popup :dialog="isOpenDialogEdit" :message="editMessage" @submit="handleSubmitEdit" :closeText="closeText" @close="isOpenDialogEdit=false" />
            </div>
            <div class="register-bottom">
                <div class="submit-data" v-if="!groupId">
                    <common-button class="btn-user-group submit-btn" :label="btnAdd" type="colored" @action="handleSubmitRegister"
                        v-ripple="false" :disabled="!isValidRegister" />
                </div>
                <div class="submit-data" v-if="groupId && openEdit">
                    <common-button class="btn-user-group submit-btn" :label="btnAdd" type="colored" @action="isOpenDialogEdit=true"
                        v-ripple="false" :disabled="!isValidRegister" />
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import CommonButton from "@/components/utils/button.vue";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import isEqual from 'lodash/isEqual';
import { getListUserApi, createUserGroupApi, getUserGroupDetailApi, updateUserGroupApi, deleteUserGroupDetailApi } from "@/api/userManagement";
import { getProductMasterApi } from "@/api/product";
import BranchRegister from "./branchRegister.vue";
import QuestionPopup from "@/components/dialogs/question-popup.vue";
import ExportPulldown from '@/components/pulldown/exportReport';
import { CollectionView } from '@mescius/wijmo';
import DataTable from '@/components/category/data-table';
import { GridEditAction } from "../managements/wijmo-extends";
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import { getProductList, getListGroupProductByName } from '@/api/product/emissionsProduct';
import { STATUS_FIELD } from "@/constants/status";

export default {
    components: { PageTitle, CommonButton, NotificationPopup, BranchRegister, QuestionPopup, ExportPulldown, DataTable },
    data() {
        return {
            dataSubmit: {
                name: '',
                user_ids: [],
                branch_ids: [],
                cfp_product_ids: []
            },
            itemsUsers: [],
            itemsUsersNames:[],
            itemsUsersIds: [],
            itemsProductsMaster: [],
            isOpenPopUpRegister: false,
            groupId: null,
            openEdit: false,
            isOpenDialogDelete: false,
            isOpenDialogEdit: false,
            deleteMessage: this.$t('register_user_group.message_delete'),
            editMessage: this.$t('register_user_group.message_edit'),
            reRenderKey: 0,
            userGroupDetail: null,
            activeUserId: null,
            titleRegisterUserGroup: this.$t('register_user_group.title_new_user_group'),
            titleDetailUserGroup: this.$t('register_user_group.title_user_group_detail'),
            tooltipState: {
                showTooltipSelectId: false,
                showTooltipIdSelected: false,
            },
            errorMessage: {
                name: ''
            },
            flex: null,
            listData: null,
            gridColumns: [],
            userGroupsList: [],
            isProducts: true
        }
    },
    async created() {
        this.actionUpdateIsLoading(true)
        this.getListUser()
        this.groupId = this.$route.params.groupId;
        if(this.groupId) {
            await this.getUserGroupDetail()
        }else {
            await this.getListProductMasterData()
        }
        this.actionUpdateIsLoading(false)
    },
    computed: {
        ...mapState("userData", ["currentUser"]),
        ...mapState("userData", ['planType']),
        isValidRegister() {
            if (this.dataSubmit.name.trim() && this.dataSubmit.user_ids.length > 0) {
                return true;
            }
            else false;
        },
        isDisabledAddUserId() {
            return isEqual(this.itemsUsersIds.sort(), this.dataSubmit.user_ids.sort());
        },
        messageRegisted() {
            return this.$t('register_user_group.message_registered_new_group')
        },
        closeText() {
            return this.$t('popup.button_cancel')
        },
        btnAdd() {
            return this.$t('register_user_group.button_add')
        }
    },
    destroyed() {
        document.removeEventListener('keydown', this.keydownEventHandler)
    },
    mounted() {
        this.isProducts = this.planType.is_cfp
        document.addEventListener("keydown", this.keydownEventHandler);
        let items = [
          {
            text: this.$t('register_user_group.hyperlink_home'),
            disabled: false,
            href: ROUTES.DASHBOARD,
          },
          {
            text: this.$t('register_user_group.hyperlink_setting'),
            disabled: false,
            href: ROUTES.SETTING,
          },
          {
            text: this.$t('register_user_group.hyperlink_user_management'),
            disabled: false,
            href: ROUTES.USER_MANAGEMENT,
          },
          {
            text: this.$t('register_user_group.hyperlink_user_group_setting'),
            disabled: false,
            href: ROUTES.GROUPS_MANAGEMENT,
          },
          {
            text: this.$t('register_user_group.label_register'),
            disabled: true,
            href: ROUTES.GROUPS_MANAGEMENT + ROUTES.REGISTER_USER_GROUP,
          },
        ]
        if(this.groupId) {
            items[items.length - 1] = {
                text: this.$t('register_user_group.label_detail'),
                disabled: true,
                href: ROUTES.GROUPS_MANAGEMENT + ROUTES.DETAIL_USER_GROUP + `/${this.groupId}`
            }
        }
        const path = this.$route.path;
        if (path.includes(ROUTES.PRODUCTS_EMISSION)) {
            items = items.map((item, index) => ( {
                ...item,
                href: index === 0 ? `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}` : `${ROUTES.PRODUCTS_EMISSION}${item.href}`,
            }))
        } 
     
        this.updateBreadCrumb(items);
    },
    methods: {
        ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoading']),
        scrollToTop() {
            if (!this.flexGrid) {
            return;
            }
            let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
            this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
        },
        async getListProductMasterData() {
            await getListGroupProductByName()
            .then((res) => {
                res.data.forEach((item) => {
                this.userGroupsList.push({
                    name: item.name,
                    status: false,
                    id: item.ids
                });
            });
            this.defineTable(this.userGroupsList);
            })
            .catch((error) => console.warn(error));
        },
        defineTable() {
            this.gridColumns = this.getGridColumns();
            this.listData         = new CollectionView([...this.userGroupsList], {
                trackChanges: true
            });
            
            this.flex.columnGroups = this.getGridColumns();
            setMinMaxSizeColumns(this.flex, this.userGroupsList);
            this.$nextTick(() => {
                this.scrollToTop();
            });
        },
        getGridColumns() {
            return [
            { 
                header: this.$t('register_user_group.table_product_name'),
                binding: 'name',
                allowSorting: false,
                isReadOnly: true,
                minWidth: getWidthByTextContent(this.$t('register_user_group.table_product_name')) + 5,
                maxWidth: 980,
                cssClassAll: 'single-row',
            
            },
            {
                header      : this.$t('register_user_group.table_allow_viewing'),
                binding     : "status",
                minWidth    : 160,
                maxWidth    : 217,
                allowSorting: false,
                dataType    : "Boolean",
                wordWrap    : true,
                cssClassAll : "single-row"
            }
            ];
        },
        initializeGrid(flexgrid) {
            this.flex = flexgrid;
            flexgrid?.beginningEdit.addHandler((s, e) => {
                let column        = s.columns[e.col];
                let rowValue      = s.rows[e.row]._data;
                this.check_status = rowValue;

                if (column.binding !== "status") {
                    return;
                }
        
                this.stackUndoWhenUncheck = new GridEditAction(s, e);
                this.handleCheckBox(rowValue);
                e.cancel = true;
            });
            this.flex.onSelectionChanged(null);    
        },
        handleCheckBox(value) {
            if (value?.status) {
                this.checkboxHandler(false);
            } else {
                this.checkboxHandler(true);
            }
        },
        checkboxHandler(check) {
            if (this.check_status.id === undefined) {
                return;
            }
            this.listData.beginUpdate();
            this.listData.currentItem.status = check;
            this.listData.endUpdate(); 
            if(check) {
                this.dataSubmit.cfp_product_ids.push(this.check_status.id)
            }
            else {
                this.dataSubmit.cfp_product_ids =  this.dataSubmit.cfp_product_ids.filter(id => id !== this.check_status.id)
            }

        },
        getSettingIcon(image) {
            if (image) {
            return require(`@/assets/icons/${image}`);
            }
            return '';
        },
        changeTooltipImage(event, type, kindTooltip) {
            if (type === "mouseleave") {
                event.target.src                        = this.getSettingIcon("helper-icon.svg");
                event.target.parentElement.style.cursor = "default";
                this.tooltipState[kindTooltip]        = false;
            } else {
                event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
                event.target.parentElement.style.cursor = "pointer";
                this.tooltipState[kindTooltip]        = true;
            }
        },
        keydownEventHandler(event) {
            if (event.key === "Delete" || event.key === "Backspace") {
                const selectedUserText = document.activeElement.innerText;
                const userIdToRemove = this.itemsUsers.find(item => item.text === selectedUserText)?.id
                if(userIdToRemove) {
                    this.handleRemoveItemUserId(userIdToRemove)
                }
            }
        },
        async getUserGroupDetail() {
            try {
                const userGroupData = await getUserGroupDetailApi(this.groupId);
                this.userGroupDetail = userGroupData.data;
                // default data submitted 
                this.dataSubmit.user_ids = this.userGroupDetail.users.map(user => user?.id)
                this.dataSubmit.name = this.userGroupDetail.name
                this.dataSubmit.branch_ids = this.userGroupDetail.branches.filter(branch => branch?.status).map(branch => branch?.id)
                this.dataSubmit.cfp_product_ids = this.userGroupDetail.cfp_products.filter(product => product?.status).map(product => product?.ids)
                this.itemsUsersNames =  this.userGroupDetail?.users?.map(user => user?.email)
                this.userGroupsList = this.userGroupDetail.cfp_products.map(product => ({
                    name: product?.name,
                    status: product?.status,
                    id: product.ids
                }))
                this.defineTable(this.userGroupsList);
                
            } catch (error) {
                console.warn(error)
            }
        },
        async getListUser() {
            try {
                const listProductMaster = await getListUserApi({contractor_id: this.currentUser.user.contractor_id, is_approve: 1, is_active: 1});
                this.itemsUsers = listProductMaster.data.map((user) => {
                    return {
                        text: user.email,
                        id: user.id,
                        hasCheckbox: false
                    };
                });
                this.itemsUsers.unshift({
                    text: this.$t('register_user_group.pulldown_select_all'),
                    hasCheckbox: true
                })
            } catch (error) {
                console.warn(error)
            }
        },
        async handleSubmitRegister() {
            try {
                const dataSubmit = {
                    ...this.dataSubmit,
                    cfp_product_ids: this.dataSubmit.cfp_product_ids.flat()
                }
                await createUserGroupApi(dataSubmit);
                this.isOpenPopUpRegister = true;
            } catch (error) {
                const { errors, message } = error;
                if(errors.name) {
                    this.errorMessage.name = errors.name[0]
                }
                this.scrollToTop();
                console.warn(error)
            }     
        },
        scrollToTop() {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
            });
        },
        async handleSubmitEdit() {
            try {
                const dataUpdate = {
                    name: this.dataSubmit.name,
                    user_ids: this.dataSubmit.user_ids,
                    cfp_product_permission_ids: this.dataSubmit.cfp_product_ids.flat(),
                    permission_ids: this.dataSubmit.branch_ids,
                    id: this.groupId
                }
                await updateUserGroupApi(dataUpdate);
                this.isOpenDialogEdit = false;
                this.navigateGotoGroupsList();
              
            } catch (error) {
                this.isOpenDialogEdit = false;
                const { errors, message } = error;
                if(errors.name) {
                    this.errorMessage.name = errors.name[0]
                }
                this.scrollToTop();
                console.warn(error)
            }     
        },
        navigateGotoGroupsList() {
            const path = this.$route.path;
            if (path.includes(ROUTES.PRODUCTS_EMISSION)) {
                this.$router.push({
                    path:ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT,
                    query: this.$route.query
                });
            }else {
                this.$router.push({
                    path: ROUTES.GROUPS_MANAGEMENT,
                    query: this.$route.query
                });
            } 
        },
        async handleSubmitDelete() {
            try {
                await deleteUserGroupDetailApi(this.groupId);
                this.navigateGotoGroupsList();
            } catch (error) {
                console.warn(error)
            }     
        },
        handleEdit() {
            this.openEdit = !this.openEdit;

            // close edit status -> update after data 
            if(!this.openEdit) {
                this.dataSubmit.user_ids = this.userGroupDetail.users.map(user => user.id)
                this.dataSubmit.name = this.userGroupDetail.name
            }
        },
        handleAddListUserId() {
            this.dataSubmit.user_ids = []
            this.dataSubmit.user_ids.push(...this.itemsUsersIds);
        },
        handleCloseDialog() {
            this.isOpenPopUpRegister = false;

            // navigate to setting group
            this.navigateGotoGroupsList()
        },
        handleRemoveItemUserId(userId) {
            const userIdsFilter = this.dataSubmit.user_ids.filter(itemId => itemId !== userId);
            const userToRemove = this.itemsUsers.find(user => user.id === userId);
            const updatedUserNames = this.itemsUsersNames.filter(userName => userName !== userToRemove.text);
            this.dataSubmit.user_ids = userIdsFilter;
            this.itemsUsersNames = updatedUserNames;
        },
        handleUpdateBranch(newBranchIds) {
            this.dataSubmit = {
                ...this.dataSubmit,
                branch_ids: newBranchIds
            }
        },
        handleChangeProductsMaster(id) {
            const hasIdProduct = this.dataSubmit.cfp_product_ids.find(productId => productId === id)
            if(hasIdProduct) {
                this.dataSubmit.cfp_product_ids =  this.dataSubmit.cfp_product_ids.filter(productId => productId !== id )
            }
            else {
                this.dataSubmit.cfp_product_ids.push(id)
            }
        },
        setActiveUserId(userId) {
            this.activeUserId = userId;
        }

    },
    watch: {
        itemsUsers() {
           this.reRenderKey++;
        },
        itemsUsersNames: {
            handler(newVal) {
                this.itemsUsersIds = this.itemsUsers.filter(user => newVal.includes(user?.text)).map(user => user.id)
            },
            deep: true
        },
        'dataSubmit.user_ids': {
            handler() {
                if(this.dataSubmit.user_ids.length === 0) { 
                    this.activeUserId = null
                }
            },
            deep: true
        },
    }
};
</script>
<style lang="scss" scoped>
.product-table {
    max-width: 517px;
}
.cut-target-setting-title {
    margin-left: 40px;
}
.title-register-user {
    margin-bottom: 22px;
    position: relative;
}

.helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}
.tooltip {
    display: block;
    justify-content: left;
    background: #ffffff;
    border-radius: 4px;
    color: #404d50;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    width: 100%;
    position: fixed;
    right: unset;
    left: 0px;
    bottom: unset;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
}
.list-user-id {
    width: 100%;
}
.list-user-id-selector {
    padding-inline-end: 24px;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
    border-radius: 6px;
    min-height: 39px;
}
.list-user-id-selector-overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
    display: inline-flex;
    opacity: 1;
}
.list-user-id-selector-item {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 22px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    user-select: none;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &.active-user {
        background: $goldMid;
        color: #fff;
    }

    span {
        display: inline-block;
        margin-inline-end: 4px;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        font-size: 12px;

    }

    .list-user-id-selector-item-remove {
        display: inline-block;
        align-items: center;
        color: currentColor;
        font-style: normal;
        line-height: inherit;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: bold;
        font-size: 10px;
        cursor: pointer;
    }
}
.register-user-group {
    position: relative;
    padding-top: 16px;
}
.register-bottom {
    max-width: 100%;
    margin: 0px auto;
    padding: 32px;
    background: #e3eded;
    border-top: 1px solid rgba(121, 134, 134, 0.12);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.register-user-group-form {
    max-width: 100%;
    margin: 0px auto;
    padding: 32px;
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-top: 34px;
    position: relative;

    .just-viewing {
        pointer-events: none;
        opacity: 0.7;
    }
}

.title-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    margin: 0;
    gap: 6px;
    width: 100%;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin-bottom: 8px;
    position: relative;
    .title-require {
        padding: 0px 4px 2px;
        width: fit-content;
        height: 20px;
        margin-top: 8px;
        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        position: absolute;
        right: 1px;
    }
}
.list-menu-btn {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    flex-direction: column;
    .list-menu-btn-text {
        width: 100%;
        align-items: center;
        .input {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px 16px 0px 16px;
            position: static;
            width: 100%;
            height: 40px;
            left: 0px;
            top: 32px;
            background: #f7f7f2;
            box-sizing: border-box;
            box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
            border-radius: 4px;
            flex: none;
            outline: none;
            &.error-input {
                border: 1px solid $redMid;
            }
        }
    }
}
.register-user-id {
    display: flex;
    justify-content: right;
}
.submit-data {
    display: flex;
    justify-content: center;
    button {
        margin: 0;
    }
}
.btn-user-group {
    background: $goldMid;
    padding: 4px 8px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff ;
    text-decoration: unset !important;
    margin-top: 9px;
    height: 28px;
    min-width: 61px;
    font-weight: 700;
    font-size: 11px;
    &:hover {
        background: #a4a184 !important;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24), 0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48)
    }
    &:disabled {
        opacity: 0.5;
        font-weight: 400;
        background: #e4e6e7 !important;
        box-shadow: unset;
        color: #000;
        pointer-events: none;
        border: 1px solid #ccc !important;
    }
}
.submit-btn {
    width: 100%;
}
.btn-edit {
    background: $goldMid;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: 4px 8px 6px;
    min-width: 61px;
    position: absolute;
    &:hover {
        background: #a4a184 !important;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24), 
        0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
    }
    &:disabled {
        background: #e4e6e7 !important;
        box-shadow: unset;
        opacity: 0.5;
        font-weight: 400;
    }
}
.edit-status {
    right: 80px;
    top: 6px;
}
.delete-status {
    right: 0;
    top: 6px;
}
.products-master {
    margin: 0 auto;
    padding: 12px;
    background: #fff;
    max-width: 230px;
    border-radius: 4px;
    margin-top: 22px;
    .products-master-wrapper {
        border: 2px solid #000;
    }
    .products-master-title {
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #000;
        text-align: center;
    }
    .products-master-item {
        border-bottom: 2px solid #000;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--text {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            display: inline-block;
            margin-left: 6px;
            min-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .products-master-item:last-child {
        border-bottom: none;
    }
}
.error-message {
    color: $redMid;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}
@include desktop {
    .delete-status {
        right: 218px;
        top:6px;
    }
    .edit-status {
        right: 310px;
        top: 6px;
    }
    .register-user-group-form {
        max-width: 60%;
    }
    .register-bottom {
        max-width: 60%;
    }
    .helper-icon {
    &.tooltip {
      position: absolute;
      width: 315px;
    }
  }
}
@include large-desktop {
    .delete-status {
        right: 284px;
        top:6px;
    }
    .edit-status {
        right: 367px;
        top: 6px;
    }
    .register-user-group-form {
        max-width: 60%;
    }
    .register-bottom {
        max-width: 60%;
    }
}
</style>
<style lang="scss">
.product-table {
    .wrap-table  {
        max-height: 457px !important
    }
}
.read-only {
    .wj-flexgrid {
        input[type=checkbox] {
            background: rgba(0, 0, 0, 0.38) !important;
        }
    }
}
</style>