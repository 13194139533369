<template>
    <div class="wrap mt-2">   
      <div
        class="main-table custom-table facility-table branch-table"
      >
        <data-table
          :data-source="branchList"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          :isReadOnly="isReadOnly"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import {
    getListBranchApi,
    getListOrganizationalApi,
  } from "@/api/facility";
  import {  CollectionView } from "@mescius/wijmo";
  import DataTable from "@/components/category/data-table";
  import { ROUTES } from "@/router/constants";
  import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
  
  export default {
    props: {
      dataUserGroupDetail: {
        type: Object,
        default: {}
      },
      isReadOnly: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        items               : [
          {
            text    : "ダッシュボード",
            disabled: false,
            href    : ROUTES.HOME_DASHBOARD
          },
          {
            text    : "設定",
            disabled: false,
            href    : ROUTES.SETTING
          },
          {
            text    : "拠点管理",
            disabled: true,
            href    : ROUTES.FACILITY_MANAGEMENT
          }
        ],
        flex                : null,
        gridColumns         : [],
        check_status        : null,
        branchList          : null,
        branchData          : [],
        listOrganizational  : [],
        statusUpdate        : {
          time: null,
          user: null
        },
        totalData           : null,
        listLayers          : [],
        layersEnable        : [],
        isEdit              : false,
        temp                : null,
        text                : null,
        limitBranch         : null,
        layersNotVisible    : [],
        listOriginalData    : [],
        cellInput: [
          'organizational_division',
          'business_name',
          'company_name',
          'country',
          'layer_3',
          'layer_4',
          'layer_5',
          'layer_6',
        ],
        celEnabel: [],
        isEditDataTable: 0,
        branchIds: [],
      };
    },
    components: {
      DataTable,
    },
    computed: {
      ...mapState("userData", ["currentUser"]),
      ...mapState("commonApp", ["loadMore", "isFilter", "isExpand", "statusAction"]),
      ...mapState("actionsTable", ["startAction"]),
      ...mapState("actionsTable", ["dataAction"]),
      ...mapState("registerData", ["isFullScreen"]),
  
      isHasData() {
        return this.canUndo || this.canRedo;
      },
    },
    async mounted() {
      // this.updateBreadCrumb(this.items);
      this.gridColumns                    = this.getGridColumns();
      const listOrganizationalApiResponse = await getListOrganizationalApi();
      this.getListOrganization(listOrganizationalApiResponse.data);
      this.getBranchList();
      this.celEnabel = ['organizational_division', 'company_name']

    },
    watch: {
      dataUserGroupDetail: {
        handler(val) {
          // split data detail 
          this.branchIds = this.dataUserGroupDetail.branches.filter(branch => branch.status).map(branch => branch.id)
        },
        deep: true,
        immediate: true
      },
    },
  
    methods: {
      ...mapActions("commonApp", ["updateBreadCrumb", "updateStatusAction"]),
      ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),
      ...mapActions("registerData", ["actionUpdateIsFullScreen"]),

      initializeGrid(flexgrid) {
        this.flex = flexgrid;
        flexgrid?.beginningEdit.addHandler((s, e) => {
          e.cancel = true;
          let column        = s.columns[e.col];
          let rowValue      = s.rows[e.row]._data;
          this.check_status = rowValue;

          if (column.binding !== "status") {
            return;
          }
          this.handleCheckBox(rowValue);
          
        });
      },
      defineTable() {
        this.gridColumns = this.getGridColumns();
        this.initialView();
  
        this.$nextTick(() => {
          this.scrollToTop();
        });
      },
  
      getGridColumns() {
        const mapLayers    = this.listLayers.filter(layer => layer.status).map((layer) => ({
          header      : layer.layer_name,
          binding     : layer.binding,
          minWidth    : getWidthByTextContent(layer.layer_name) + 5,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          cssClass    : layer.cssClass,
          isReadOnly  : false,
          wordWrap    : true,
          cssClassAll : "single-row"
        }));
        const hiddenLayers = this.layersNotVisible.map((layer) => ({
          header      : layer.layer_name,
          binding     : layer.binding,
          minWidth    : 217,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          cssClass    : layer.cssClass,
          isReadOnly  : layer.isReadOnly,
          visible     : layer.visible,
          wordWrap    : true,
          cssClassAll : "single-row"
        }));
        return [
          {
            header      : "#",
            binding     : "id",
            allowSorting: false,
            isReadOnly  : true,
            visible     : false
          },
          {
            header      : this.$t('register_user_group.table_contractor_name'),
            binding     : "contractor_name",
            minWidth    : getWidthByTextContent(this.$t('register_user_group.table_contractor_name')) + 5,
            maxWidth    : 217,
            allowSorting: false,
            // dataMap     : new wjGrid.DataMap(this.listOrganizational, "id", "value"),
            isRequired  : false,
            // editor      : new AutoComplete(document.createElement("div"), {
            //   itemsSource      : this.listOrganizational,
            //   selectedValuePath: "id",
            //   displayMemberPath: "value"
            // }),
            wordWrap    : true,
            cssClassAll : "single-row",
            // isReadOnly  : true,
          },
          {
            header      : this.$t('register_user_group.table_corporate_name'),
            binding     : "company_name",
            minWidth    : 515,
            maxWidth    : 980,
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            cssClassAll : "single-row",
            // isReadOnly  : true,
          },
          ...mapLayers,
          ...hiddenLayers,
          // {
          //   header: " ",
          //   binding: 'add_column',
          //   minWidth    : 160,
          //   maxWidth    : 217,
          //   allowSorting: false,
          //   cssClass : "auto-increment"
          // },
          {
            header      : this.$t('register_user_group.table_allow_viewing'),
            binding     : "status",
            minWidth    : 160,
            maxWidth    : 217,
            allowSorting: false,
            dataType    : "Boolean",
            wordWrap    : true,
            cssClassAll : "single-row"
          }
        ];
      },
      handleCheckBox(value) {
        if (value?.status) {
          this.checkboxHandler(false);
        } else {
          this.checkboxHandler(true);
        }
      },
      checkboxHandler(check) {
        if (this.check_status.id === undefined || !this.check_status.organizational_division || !this.check_status.company_name) {
          return;
        }

        if(check) {
        this.branchIds.push(this.check_status.id)
        }
        else {
          this.branchIds =  this.branchIds.filter(id => id !== this.check_status.id)
        }
        this.$emit('updateBranch', this.branchIds);
        this.branchList.beginUpdate();
        this.branchList.currentItem.status = check;
        this.branchList.endUpdate();
      },
      getListOrganization(data) {
        data.forEach((item) => {
          this.listOrganizational.push({
            id   : item.id,
            value: item.type
          });
        });
      },
      scrollToTop() {
        if (!this.flex) {
          return;
        }
      },
      initialView() {
        this.branchList       = new CollectionView([...this.branchData]);
        this.listOriginalData = JSON.parse(JSON.stringify(this.branchList.items));
  
        this.flex.columnGroups = this.gridColumns;
        setMinMaxSizeColumns(this.flex, this.branchData);
      },
      async getBranchList() {
        await getListBranchApi({
          contractor_id: this.currentUser.user.contractor_id, is_active: 1
        }).then((responseApiListBranch) => {
          this.listLayers       = [];
          this.listLayers       = responseApiListBranch?.layer.map((layer) => {
            let binding = `layer_${layer.layer_index}`;
            if (layer.layer_index === 1) {
              binding = "business_name";
            } else if (layer.layer_index === 2) {
              binding = "country";
            }
            return {
              ...layer,
              cssClass  : !layer.status ? "auto-increment" : "",
              isReadOnly: true,
              binding
            };
          }).sort((firstLayer, nextLayer) => firstLayer.id - nextLayer.id);
          this.layersEnable     = responseApiListBranch.layer?.map((layer) => ({
            status: layer.status,
            id    : layer.id
            // cssClass: !layer.status ? 'auto-increment' : ''
          }));
          this.limitBranch      = Number(responseApiListBranch.number_limit_branch);
          this.layersNotVisible = [];
          const branchesDetail = this.dataUserGroupDetail?.branches;
          this.branchData       = responseApiListBranch.data?.map((item, index) => {
            let status = false;
            let id = item.id;
            if(branchesDetail && branchesDetail.length > 0) {
              const findBranch = branchesDetail.find(branch => branch?.branch_id === item.id)
              if(findBranch) {
                status = findBranch.status
                // using id permission
                id = findBranch.id
              }
            }
            return item && {
            ...item,
            id: id,
            index: index + 1,
            status: status
          }
          });
          this.totalData        = this.branchData.length;
          this.statusUpdate     = {
            time: responseApiListBranch.updated_at_latest,
            user: responseApiListBranch.user_updated
          };
        }).catch(() => {
        });
        this.defineTable(this.branchData);
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import './styles/index.scss';
  </style>
